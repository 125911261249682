import React from 'react'

import { styled } from "@mui/material/styles"
import popUpStyle from '../../Layout/PopupStyle.js';
import imgMantOceanicaDesktop from '../../../../static/mantenimiento_ocean_1920.jpg'
import imgMantOceanicaMobile from '../../../../static/mantenimiento_ocean_900.jpg'

import imgMantPiramideDesktop from '../../../../static/mantenimiento_piram_1920.jpg'
import imgMantPiramideMobile from '../../../../static/mantenimiento_piram_900.jpg'

const whiteColor = "#FFF";
const blackColor = "#000";
const hexToRgb = input => {
    input = input + "";
    input = input.replace("#", "");
    let hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
        throw new Error("input is not a valid hex color.");
    }
    if (input.length === 3) {
        let first = input[0];
        let second = input[1];
        let last = input[2];
        input = first + first + second + second + last + last;
    }
    input = input.toUpperCase(input);
    let first = input[0] + input[1];
    let second = input[2] + input[3];
    let last = input[4] + input[5];
    return (
        parseInt(first, 16) +
        ", " +
        parseInt(second, 16) +
        ", " +
        parseInt(last, 16)
    );
};


const NewDiv = styled("div")(({ theme }) => ({

    background: whiteColor,
    position: "relative",
    zIndex: "3",
    margin: "20px 15px",

    "@media (max-width: 576px)": {
        marginTop: "10px"
    },
    "@media (max-width: 830px)": {
        marginLeft: "10px",
        marginRight: "10px"
    },
    
    borderRadius: "6px",
    boxShadow:
        "0 16px 24px 2px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 6px 30px 5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
}))

const NewImgDesktop = styled("img")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...popUpStyle[style] }
})

const NewImgMobile = styled("img")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...popUpStyle[style] }
})

export default function PresentationPageForMaintenance() {
    const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
    const altText = insuranceCompany === 'OCEANICA'? ' Portal de Oceánica de Seguros en Mantenimiento ':' Portal de Seguros Pirámide en Mantenimiento '
    const imgMantDesktop = insuranceCompany === 'OCEANICA'? imgMantOceanicaDesktop:imgMantPiramideDesktop
    const imgMantMobile = insuranceCompany === 'OCEANICA'? imgMantOceanicaMobile:imgMantPiramideMobile

    React.useEffect(() => {
        document.body.scrollTop = 0;
    });
    return (
        <NewDiv>
            <NewImgDesktop
                alt={altText}
                src={imgMantDesktop}
                ownerState={{
                    style: "modalImgDesktop"
                }}
            />

            <NewImgMobile
                alt={altText}
                src={imgMantMobile}
                ownerState={{
                    style: insuranceCompany === 'OCEANICA' ? "modalImg" : "modalImgPiramide",
                }}
            />
        </NewDiv>
        
    )
}
