import React, {useEffect, useState} from "react"


import { initAxiosInterceptors } from 'utils/axiosConfig'
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'
import PresentationPageForMaintenance from "../LandingPageMaterial/Views/PresentationPage/PresentationPageForMaintenance"

export default function IndexMaterial () {
  const loading = useLoading()
  const dialog = useDialog()
  const [ready,setReady] = useState(false) 

  useEffect(() => {
  	initAxiosInterceptors(dialog,loading)
    setReady(true);
  },[])

  return (
  	<>
    {
    	ready && <PresentationPageForMaintenance/>   
    }
    </>
  )
}