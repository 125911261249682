const PopUpStyle = {
    modalContainer: {
        zIndex: 10000, 
        backgroundColor: 'rgba(0,0,0,0.5)', 
        width: '100vw', 
        height: '100vh', 
        display: 'flex', 
        position: 'fixed', 
        justifyContent: 'center', 
        alignItems: 'center', 
        top: 0, 
        left: 0,
    },
    modalWithButtonPaddingBottom:{
        paddingBottom: "46px",
    },
    modalBodyExtraPaddingBottom:{
        paddingBottom: "44px",
        position: 'absolute',
        maxWidth: "60%",
        height: "63%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px",

        "@media (max-width: 400px)": {
            justifyContent: 'center',
            width: "100%",
            height: "92%",
            maxWidth:"72%"
        },

        "@media (min-width: 400px) and (max-width: 445px)": {
            justifyContent: 'center',
            width: "80%",
            height: "50%",
        },

        "@media (min-width: 445px) and (max-width: 500px)": {
            justifyContent: 'center',
            width: "60%",
            height: "87%",
        }
    },
    modalBody: {
        position: 'absolute',
        maxWidth: "60%",
        height: "63%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px",
        "@media (max-width: 400px)": {
            // justifyContent: 'center',
            // width: "90%",
            // height: "67%",
            justifyContent: 'center',
            width: "100%",
            height: "92%",
            maxWidth:"72%"
        },

        "@media (min-width: 400px) and (max-width: 445px)": {
            justifyContent: 'center',
            width: "80%",
            height: "50%",
        },

        "@media (min-width: 445px) and (max-width: 500px)": {
            justifyContent: 'center',
            width: "60%",
            height: "87%",
        }
    },
    customStyle:{
        position: 'absolute',
        maxWidth: "60%",
        height: "95%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px",

        "@media (max-width: 400px)": {
            justifyContent: 'center',
            width: "90%",
            height: "67%",
        },

        "@media (min-width: 400px) and (max-width: 445px)": {
            justifyContent: 'center',
            width: "80%",
            height: "50%",
        },

        "@media (min-width: 445px) and (max-width: 500px)": {
            justifyContent: 'center',
            width: "60%",
            height: "87%",
        }
    },
    customStyleInsured:{
        position: 'absolute',
        maxWidth: "75%",
        height: "70%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px",

        "@media (max-width: 400px)": {
            justifyContent: 'center',
            width: "90%",
            height: "80%",
        },

        "@media (min-width: 400px) and (max-width: 445px)": {
            justifyContent: 'center',
            width: "80%",
            height: "94%",
        },

        "@media (min-width: 445px) and (max-width: 500px)": {
            justifyContent: 'center',
            width: "60%",
            height: "87%",
        }
    },
    modalBodyPiramide: {
        position: 'absolute',
        width: "43%",
        height: "87%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px ",
        // paddingBottom: "46px",

        "@media (max-width: 460px)": {
            justifyContent: 'center',
            width: "96%",
        },

        "@media (min-width: 460px) and (max-width: 700px)": {
            justifyContent: 'center',
            width: "77%",
        },

        "@media (min-width: 1080px)": {
            justifyContent: 'center',
            width: "23%",
        },
    },
    modalBodyHorizontal: {
        position: 'absolute',
        width: "56%",
        maxWidth: '767px',
        height: "51%",
        maxHeight: '381px',
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px ",
    },
    btnClose: {
        position: 'absolute',
        top: 3,
        right: 4,
        width:"20px",
        color:'gray',
        cursor: 'pointer',
        "@media (max-width: 500px)": {
            top: 1,
            right: 1,
        }
    },
    modalImg: {
        width: '100%',
        objectFit: 'contain',
        height: '100%',
        cursor: 'pointer',
        borderRadius:"5px 5px 0 0",
        objectPosition: 'center',
        "@media (min-width: 500px)": {
            display: 'none',
        }
    },
    modalImgPiramide: {
        width: '100%',
        objectFit: 'contain',
        height: '100%',
        cursor: 'pointer',
        borderRadius:"5px 5px 0 0",
        objectPosition: 'center',
        "@media (min-width: 500px)": {
            display: 'none',
        }
    },
    modalImgDesktop: {
        width: '100%',
        objectFit: 'unset',
        height: '100%',
        cursor: 'pointer',
        borderRadius:"5px 5px 0 0",
        objectPosition: 'center',
        "@media (max-width: 500px)": {
            display: 'none',
        }
    },
    modalImgPiramideDesktop: {
        width: '100%',
        objectFit: 'unset',// contain
        height: '100%',
        cursor: 'pointer',
        borderRadius:"5px 5px 0 0",
        objectPosition: 'center',
        "@media (max-width: 500px)": {
            display: 'none',
        }
    },
    time: {
        color: 'black',
        textAlign: 'center',
        fontSize: '2rem',
        paddingTop: '0.5rem',
        position: 'relative',
        bottom: "35px",
        margin: "10px",
        left: "7px",
        "@media (max-width: 500px)": {
            fontSize: '19px',
            bottom: "21px",
        },
    },
    timePiramide: {
        color: 'black',
        textAlign: 'center',
        fontSize: '2rem',
        paddingTop: '0.5rem',
        position: 'relative',
        bottom: "35px",
        margin: "4px",
        left: "7px",
        "@media (max-width: 500px)": {
            fontSize: '19px',
            bottom: "21px",
        }
    },
    titleModal: {
        color:"black",
        fontSize:"20px",
        fontWeight:"800",
        textAlign:"center",
        marginTop:"20px"
    },
    containerbtn : {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"10px",
    },
    btn: {
        borderRadius:"4px",
        border:"none",
        color:"white",
        fontSize:"13px",
        width:"250px",
        height:"31px",
        alignContent:"center",
        alignItems:"center",
        display:"flex",
        justifyContent:"center",
        textAlign: 'center'
    },
    btnLabel: {
        color:"white",
        padding:"2px",
        "&:hover":{
            textDecoration:"none",
            color:"white",
        },
        fontWeight:700,
        textAlign:"center",
    },
    containerCounter : {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: "1rem"
    },
    // Estilos popup clinicas
    modalBodyClinics: {
        position: 'absolute',
        maxWidth: "60%",
        height: "90%",
        backgroundColor:"white",
        boxShadow: '0 8px 6px -6px black',
        borderRadius:"5px",
        "@media (max-width: 400px)": {
            // justifyContent: 'center',
            // width: "90%",
            // height: "67%",
            justifyContent: 'center',
            width: "100%",
            height: "72%",
            maxWidth:"84%"
        },

        "@media (min-width: 400px) and (max-width: 445px)": {
            justifyContent: 'center',
            // width: "80%",
            // height: "50%",
            height: "72%",
            maxWidth: "84%"
        },

        "@media (min-width: 445px) and (max-width: 500px)": {
            justifyContent: 'center',
            width: "60%",
            height: "90%",
        }
    },
    modalImgPiramideClinics: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        borderRadius: "5px 5px 0 0",
        objectPosition: 'center',
        "@media (min-width: 500px)": {
            display: 'none',
        }
    },
}

export default PopUpStyle;
